* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.modal {
  position: fixed; /* Stay in place */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

html,
body {
  overflow-y: hidden;
}

.backdrop-blur-sm {
  overflow: scroll !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard-container::-webkit-scrollbar,
.scrolls::-webkit-scrollbar,
.backdrop-blur-sm::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard-container,
.scrolls,
.backdrop-blur-sm {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
